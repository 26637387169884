import React from "react";
import Typed from "react-typed";

const TypingText = ({
  textArray,
  typingSpeed = 40,
  backSpeed = 20,
  loop = false,
}) => {
  return (
    <h3 className="text-3xl md:text-5xl font-light text-center mt-4">
      <Typed
        strings={textArray}
        typeSpeed={typingSpeed}
        backSpeed={backSpeed}
        loop={loop}
      />
    </h3>
  );
};

export default TypingText;
