import React from "react";

const PortfolioImageComponent = ({ imageUrl, description, radius="rounded" }) => {
  return (
    <div className="not-prose w-full flex flex-col items-center bg-zinc-900 pt-16 pb-8">
      <img
        src={require(`../images/${imageUrl}`)}
        alt={description}
        className={`w-3/4 mx-auto ${radius === "rounded" ? "rounded-3xl" : ""} `}
      />
      <h6 className="text-center pt-8 text-lg text-white">{description}</h6>
    </div>
  );
};

export default PortfolioImageComponent;
