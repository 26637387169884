const fivetasks = {
  id: "4",
  status: "active",
  title: "Five Tasks",
  link: "https://getfive.app/",
  soon: "Full portfolio page is coming soon, in the meantime you can visit the site at:",
  cover: "five-cover.jpg",
  description:
    "The to-do app with a limit of five tasks.",
  summary:
    "By putting a limitation on your to-do list, you are forced to prioritise and focus on what’s important.",
  tools: [
    "SwiftUI",
    "Xcode",
    "iOS",
    "CoreData",
    "CloudKit",
    "UX Design",
  ],
  content: (
    <>
      <p>
        <a href="https://getfive.app/" data-type="page">
          Five Tasks
        </a>{" "}
        - The to-do app with a limit.
      </p>
      <ul>

        <li>By putting a limitation on your to-do list, you are forced to prioritise and focus on what’s important.</li>

        <li>Five helps you do this with an incredibly simple app, you can only have five outstanding tasks.</li>

        <li>With Five you will stop taking on too many tasks, many of which you wouldn’t have time to complete anyway.</li>

        <li>So welcome to the first step toward simplifying your to-do list, and getting the important tasks done!</li>
      </ul>
      <div className="flex items-center justify-center">
        <iframe width='560' height='315'
                src="https://www.youtube.com/embed/HnKilP969sI?&theme=dark&autoplay=1&autohide=1&modestbranding=1&showinfo=0&rel=0"
                frameBorder="0"></iframe>
      </div>
    </>
  ),
};

export default fivetasks;
