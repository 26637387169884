import React from 'react';

const Footer = () => {
    return (
      <footer className="bg-white shadow">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-center items-center space-x-3">
            <a href="https://www.linkedin.com/in/spencer-walden-524aa851/" className="text-gray-500 hover:text-gray-600">LinkedIn</a>
            <a href="https://twitter.com/swaldy" className="text-gray-500 hover:text-gray-600">Twitter</a>
          </div>
          <p className="mt-4 text-center text-gray-500">© 2023 Spencer Walden. All rights reserved.</p>
        </div>
      </footer>
    );
  };

export default Footer;