const discountkit = {
  id: "8",
  status: "inactive",
  title: "DiscountKit",
  soon: "Full portfolio page is coming soon.",
  cover: "discountkit-cover.png",
  description: "Building a Woolworths Online sale alert tool.",
  summary:
    "DiscountKit - We’ll alert you when your favourite Woolworths Online products are on sale.",
  tools: [
    "NodeJS",
    "ExpressJS",
    "Javascript",
    "HTML",
    "CSS",
    "Foundation",
    "Puppeteer",
    "Web Scraping",
  ],
  content: (
    <>
      <p>
        <a
          href="http://discountkit.com.au"
          data-type="URL"
          data-id="discountkit.com.au"
        >
          DiscountKit
        </a>{" "}
        - We’ll alert you when your favourite&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://woolworths-supermarkets.sjv.io/c/1249035/419304/7464?u=https%3A%2F%2Fwww.woolworths.com.au%2F"
          target="_blank"
        >
          Woolworths Online
        </a>
        &nbsp;products are on sale. So you can bulk buy and save $$$.
      </p>
      <ul>
        <li>
          I built this tool originally for Chocolab so we could reduce our
          ingredient cost. I then released it publicly as I felt others could
          benefit from it. It made a small amount of revenue through an
          affiliate program.
        </li>
        <li>
          I’ve since discontinued the product due to issues with being blocked
          by Woolworths as well as the affiliate program ending.
        </li>
        <li>The app was built with Node.js</li>
      </ul>
    </>
  ),
};

export default discountkit;
