import React from "react";
import projects from "../data/projects.js";
import "../animation.css";
import Header from "./Header";
import ToolsComponent from "./ToolsComponent.js";

const Portfolio = () => {
  return (
    <div>
      <Header />
      <section className="lg:container mx-auto py-12">
        <div className="flex flex-wrap mt-4">
          {projects.map((project) => (
            <div key={project.id} className="w-full py-4 px-4">
              <a href={`/project/${project.id}`}>
                <div className="bg-white shadow-sm rounded-sm max-sm:flex-col md:flex md:space-x-16 px-12 py-12 md:px-12 md:py-12 lg:px-40 lg:py-20 hover-scale">
                  <img
                    className="w-36 h-36 md:w-60 md:h-60 my-auto mx-auto md:mx-0"
                    src={require(`../images/${project.cover}`)}
                    alt="Project"
                  />
                  <div className=" text-center md:text-left">
                    <h3 className="text-3xl md:text-5xl font-semibold py-3 mb-2">
                      {project.title}
                    </h3>
                    <ToolsComponent tools={project.tools} />
                    <p className="text-xl mt-3 mb-4 text-gray-600">
                      {project.description}
                    </p>
                    <span className="text-lg mt-4 inline-block text-blue-600 hover:text-blue-800">
                      Read More
                    </span>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
