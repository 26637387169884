import React from "react";
import { useParams } from "react-router-dom";
import projects from "../data/projects";
import ToolsComponent from "./ToolsComponent";

const ProjectDetails = () => {
  const { id } = useParams();
  const projectId = id;
  const project = projects.find((project) => project.id === projectId);

  return (
    <div className="-mt-5 mx-auto">
      <section className="content-section dark-section text-center">
        <div className="prose lg:prose-xl mx-auto">
          <a href="/" className="text-white text-center no-underline">
            Back to all projects
          </a>
          <h2 className="text-5xl md:text-7xl font-bold text-center text-white mb-0 pb-4">
            {project.title}
          </h2>
          <div className="text-white text-lg md:text-xl">{project.summary}</div>
        </div>
      </section>
      <section className="px-6 pt-2 bg-white">
        <div className="prose lg:prose-xl mx-auto">
          <ToolsComponent tools={project.tools} />
        </div>
      </section>
      {(() => {
        if (project.status === "active") {
          return (
            <section className="content-section white-section">
              <div className="prose lg:prose-xl mx-auto">{project.content}</div>
            </section>
          );
        } else {
          return (
            <section className="content-section white-section">
              <div className="prose lg:prose-xl mx-auto text-center">
                {project.soon}
                <br />
                {project.link ? (
                  <a href={project.link} target="_blank" rel="noreferrer">
                    Link
                  </a>
                ) : (
                  ""
                )}
              </div>
            </section>
          );
        }
      })()}
      <div className="flex justify-center space-x-12 max-sm:px-6 bg-white pb-12">
        {/* <a
          href={`/project/${project.id - 1}`}
          className={`mt-4 inline-block text-blue-600 hover:text-blue-800 ${
            parseInt(project.id) === 1 ? "invisible" : ""
          }`}
        >
          Prev
        </a> */}
        <a
          href="/"
          className="mt-4 inline-block text-blue-600 hover:text-blue-800"
        >
          Back to all projects
        </a>
        {/* <a
          href={`/project/${parseInt(project.id) + 1}`}
          className={`mt-4 inline-block text-blue-600 hover:text-blue-800 ${
            parseInt(project.id) === projects.length ? "invisible" : ""
          }`}
        >
          Next
        </a> */}
      </div>
    </div>
  );
};

export default ProjectDetails;
