const autounsubscribe = {
  id: "3",
  status: "inactive",
  link: "https://autounsubscribe.me",
  soon: "Full portfolio page is coming soon, in the meantime you can visit the site at:",
  title: "AutoUnsubscribe",
  cover: "autounsubscribe-cover.jpg",
  description: `Unsubscribing from unread emails. Automatically.`,
  summary:
    "Automatically unsubscribe from unwanted emails that you never open, so you can focus on the emails that matter, saving yourself time and effort.",
  tools: [
    "Ruby On Rails",
    "StimulusJS",
    "Ruby",
    "Javascript",
    "HTML",
    "CSS",
    "IMAP",
    "PostgreSQL",
    "Heroku",
    "Delayed Job",
    "TailwindCSS",
  ],
  content: (
    <>
      <ul>
        <li>
          AutoUnsubscribe aims to help reduce email spam, as this is a large
          problem I had when I ran Chocolab, as I missed important emails, due
          to spam from our suppliers.
        </li>
        <li>It is built with Ruby on Rails</li>
      </ul>
      <figure>
        <img
          decoding="async"
          width="1024"
          height="563"
          src="https://spencerwalden.com.au/wp-content/uploads/2023/02/twitter_card-1024x563.png"
          alt="AutoUnsubscribe Screenshot"
          className="w-full rounded-lg"
        />
      </figure>
    </>
  ),
};

export default autounsubscribe;
