const sandstone = {
  id: "9",
  status: "active",
  title: "Sandstone Technology",
  cover: "sandstone-cover.jpg",
  soon: (
    <>
      <p>Full portfolio page is coming soon.</p>
    </>
  ),
  description:
    "Designing, building and prototyping a cross platform mobile banking app.",
  summary:
    "Designing, building and prototyping a cross platform mobile banking app.",
  tools: [
    "Axure",
    "PhoneGap",
    "Javascript",
    "HTML",
    "CSS",
    "Angular.js",
    "Backbone.js",
    "ReactJS",
    "Visual Design",
    "UX Design",
    "User Testing",
    "User Research",
    "Wireframing",
    "Adobe Suite",
    "Sketch",
    "iBeacon",
  ],
  content: (
    <>
      <h3>This section is a work in progress - more coming soon.</h3>
      <p>
        Sandstone Technology is a company that builds digital banking software
        it then white labels to banks. Throughout my time here I worked with
        clients such as ANZ, RaboDirect, Mandiri Bank etc.
      </p>
      <p>
        I worked across a number of roles including frontend development
        (Javascript, HTML, CSS, Angular.js), visual design and UX design with a
        big focus on building and user testing prototypes.
      </p>
      <p>
        Before I left the company I was the sole UX designer and UX lead for the
        mobile banking app, for which I have included screenshots below. I ran
        user research, user testing, made wireframes and visual designs for the
        app. User testing was done via Axure and a PhoneGap Javascript app that
        I maintained.
      </p>
      <img
        className="rounded-lg"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens_07-Account-list.png"
        alt="Account List"
      />
      <img
        className="rounded-lg"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens_balance-peek-01.png"
        alt="Balance Peek"
      />
      <img
        className="rounded-lg col-span-2"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens-12.png"
        alt="App Screenshot"
      />
      <img
        className="rounded-lg"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens_05-Menu.png"
        alt="Menu"
      />
      <img
        className="rounded-lg"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens-29.png"
        alt="App Screenshot 2"
      />
      <img
        className="rounded-lg"
        src="https://spencerwalden.com.au/wp-content/uploads/2023/01/abank_app_Nemo-screens_receipt.png"
        alt="Receipt"
      />
    </>
  ),
};

export default sandstone;
