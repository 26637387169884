import React from "react";
import "./App.css";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import ProjectDetails from "./components/ProjectDetails";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Portfolio /> },
    { path: "/project/:id", element: <ProjectDetails /> },
  ]);
  return routes;
};

function App() {
  return (
    <div className="bg-gray-100">
      <Router>
        <AppRoutes />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
