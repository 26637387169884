import PortfolioImageComponent from "../../components/PortfolioImageComponent";

const checkyourlist = {
  id: "1",
  status: "active",
  title: "CheckYourList",
  cover: "checkyourlist-cover.jpg",
  description: "Building a repeatable checklist app to help with ADHD.",
  summary: (
    <>
      <p>
        For years I manually unchecked items in todo list apps for checklists
        that I needed to repeat. I wanted to build an app would achieve this
        with a better user experience. I built a SwiftUI based iOS app called
        CheckYourList.
      </p>
      <a
        href="https://checkyourlist.app"
        target="_blank"
        rel="noreferrer"
        className="text-white no-underline"
      >
        See live app
      </a>
    </>
  ),
  tools: [
    "SwiftUI",
    "Swift",
    "iOS",
    "CloudKit",
    "CoreData",
    "WatchOS",
    "Xcode",
    "Adobe XD",
    "WatchOS",
    "MacOS",
  ],
  content: (
    <>
      <h2>The Problem</h2>
      <p>
        In today's fast-paced world, we are constantly bombarded with new
        information, making even the simplest tasks, like remembering what to
        take with us when we leave the house, feel overwhelming. As we strive to
        navigate this information overload, there is a pressing need to offload
        some of our cognitive load.
      </p>
      <h2>The Solution</h2>
      <p>
        CheckYourList is an iOS app that allows users to create and manage
        repeatable checklists through a clean and efficient interface. It's been
        designed to:
      </p>
      <ul>
        <li>Be easy-to-use interface for creating and reusing checklists.</li>
        <li>Have a clear display of completed and incomplete tasks.</li>
        <li>
          A quick and simple unchecking of entire lists for immediate reuse.
        </li>
      </ul>
      <h2>The Process: From Idea to App Store</h2>
      <PortfolioImageComponent
        imageUrl="checkyourlist-designing-groups.png"
        description="Designing the ability to group checklists together."
      />
      <p>
        Initially, the primary target user was myself, so the first objective
        was to develop a usable prototype that could address my problem.
      </p>
      <p>
        Starting with basic wireframes for essential app functionality, I
        quickly transitioned into coding and created a working SwiftUI prototype
        in just two days. To evaluate its effectiveness, I tested the app
        prototype with some friends and family members whose positive feedback
        indicated that the product was worth pursuing. After refining the design
        using Adobe XD, the initial version was launched on the App Store within
        a few months after development commenced. The app's popularity gained
        momentum through a Hacker News post that reached the front page.
      </p>

      <h2>The Result: An Improved Daily Routine</h2>
      <p>
        CheckYourList has revolutionized the way over a thousand users,
        including myself, manage daily routines, packing lists, and other
        recurring tasks. The app has not only eliminated the need for manually
        unchecking items in to-do lists but has also instilled confidence in
        users about having everything they need. By streamlining checklists,
        CheckYourList frees up mental space and reduces the stress of everyday
        life for its users.
      </p>

      <ul>
        <li>
          The app is currently live in the app store and is slowly growing.
        </li>
        <li>Initial wireframes were completed with pen &amp; paper.</li>
        <li>
          Initial design work was done in Adobe XD, however eventually moved to
          pen &amp; paper straight to code.
        </li>
        <li>Marketing website is built on top of GatsbyJS/ReactJS</li>
        <li>Works on WatchOS, MacOS, iPadOS and iOS</li>
      </ul>
    </>
  ),
};

export default checkyourlist;
