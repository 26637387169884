const checkyourlistsite = {
  id: "2",
  status: "active",
  title: "CheckYourList Website",
  cover: "checkyourlist-cover.jpg",
  description:
    "GatsbyJS marketing site for CheckYourList with an AI generated checklist library",
  summary: (
    <>
      <p>
        CheckYourList's marketing site was built using GatsbyJS/ReactJS. It also
        includes an experimental AI generated checklist library using the OpenAI
        GPT-4 API. It also has an open page where I share revenue and my
        completed todo items.
      </p>
      <a
        href="https://checkyourlist.app"
        target="_blank"
        rel="noreferrer"
        className="text-white no-underline"
      >
        See live site
      </a>
      <br />
      <a
        href="https://checkyourlist.app/checklists"
        target="_blank"
        rel="noreferrer"
        className="text-white no-underline"
      >
        See checklist library
      </a>
    </>
  ),
  tools: [
    "React",
    "GatsbyJS",
    "Javascript",
    "HTML",
    "CSS",
    "SEO",
    "GPT-4 API",
    "GraphQL",
    "TailwindCSS",
  ],
  content: (
    <>
      <h3 className="text-center">More coming soon.</h3>
    </>
  ),
};

export default checkyourlistsite;
