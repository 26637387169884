import React from "react";

const ToolsComponent = ({ tools }) => {
  return (
    <div className="flex flex-wrap">
      {tools.map((tool, index) => (
        <span
          key={index}
          className="inline-block px-2 py-1 mr-1 mb-1 text-xs rounded-md bg-gray-100 text-gray-600"
        >
          {tool}
        </span>
      ))}
    </div>
  );
};

export default ToolsComponent;
