const beeptestwatch = {
  id: "7",
  status: "inactive",
  title: "Beep Test Watch",
  link: "https://beeptestwatch.com",
  soon: "Full portfolio page is coming soon, in the meantime you can visit the site at:",
  cover: "beep-cover.jpg",
  description:
    "Beep Test Watch - A beep test for Apple Watch. A SwiftUI based iOS and WatchOS app.",
  summary:
    "Beep Test Watch - A beep test for Apple Watch. A SwiftUI based iOS and WatchOS app.",
  tools: [
    "WatchOS",
    "Apple HealthKit",
    "SwiftUI",
    "Xcode",
    "iOS",
    "CoreData",
    "CloudKit",
    "UX Design",
  ],
  content: (
    <>
      <p>
        <a href="https://beeptestwatch.com" data-type="page">
          Beep Test Watch
        </a>{" "}
        - A beep test for Apple Watch. A SwiftUI based iOS and WatchOS app.
      </p>
      <ul>
        <li>
          I built this app as I wanted to be able to use haptics on Apple Watch
          to run a fitness test called a beep test.
        </li>
        <li>The app integrates with Apple Health and records workout data.</li>
        <li>Later build an iPhone version.</li>
      </ul>
    </>
  ),
};

export default beeptestwatch;
