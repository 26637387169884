const projects = [];

const projectFiles = require.context("./project", true, /\.js$/);

projectFiles.keys().forEach((key) => {
  const project = projectFiles(key).default;
  projects.push(project);
});

projects.sort((a, b) => {
  return a.id - b.id;
});

export default projects;
