import React from "react";
import TypingText from "./TypingText";

const Header = () => {
  const textArray = [
    "Front End Engineer",
    "Design Technologist",
    "Product Designer",
    "UI/UX Designer",
    "Software Engineer",
    "iOS Engineer",
    "I Build Things. Let's Build Something Together?",
  ];

  return (
    <header className="bg-white">
      <div className="flex justify-center items-center flex-col">
        <div className="my-24">
          <h1 className="text-5xl md:text-7xl font-semibold text-center">
            Spencer Walden
          </h1>
          <TypingText textArray={textArray} />
        </div>
      </div>
    </header>
  );
};

export default Header;
