import PortfolioImageComponent from "../../components/PortfolioImageComponent";

const chocolab = {
  id: "6",
  status: "active",
  title: "Chocolab",
  cover: "chocolab-cover.jpg",
  description: "Chocolab - Send a personalised chocolate gift.",
  summary: "Chocolab - Send a personalised chocolate gift.",
  tools: [
    "Wordpress",
    "PHP",
    "Javascript",
    "HTML",
    "CSS",
    "Ecommerce",
    "Python",
    "Tkinter",
    "WooCommerce",
    "PrintNode",
    "Raspberry Pi",
  ],
  content: (
    <>
      <h3>This section is a work in progress - more coming soon.</h3>
      <PortfolioImageComponent
        imageUrl="chocolab-site.png"
        description="One of Chocolab's home page iterations."
        radius="nil"
      />
      <p>
        <a href="https://chocolab.com.au">Chocolab</a> - Send a personalised
        chocolate gift. Please note the current website does not reflect my
        work, as the new owners have changed it. I’ve included some screenshots
        below of some of my designs.
      </p>
      <p>
        Chocolab is a eCommerce business I founded in 2015 with my wife and
        recently sold in July 2022. It was built on top of WordPress and
        WooCommerce. The business later opened a retail store and cafe before
        it’s sale.
      </p>
      <p>
        I worked across the entire business, from marketing to tech, to managing
        staff and meeting orders. Here’s a list:
      </p>
      <ul>
        <li>Managed staff</li>
        <li>Package design</li>
        <li>Branding</li>
        <li>Product photography</li>
        <li>Order meeting process</li>
        <li>Producing and packaging chocolate</li>
        <li>Design and development of website</li>
        <li>Development of custom plugins to better manage bespoke orders</li>
        <li>
          Development of a chocolate creation lab where customers could build
          their own chocolate bars
        </li>
        <li>
          Custom software to estimate the day to day profitability of sales
        </li>
        <li>
          Custom software to look up the weather on scanning an order and then instruct staff how to package.
        </li>
        <li>Custom python Tkinter apps</li>
        <li>Raspberry Pi based order scanning app</li>
        <li>Primary marketer</li>
        <li>Sent and designed EDMs</li>
        <li>Managed and grew social media accounts</li>
        <li>
          Maintained physical machinery from electrical work, to routine
          maintenance.
        </li>
      </ul>
    </>
  ),
};

export default chocolab;
