const stellarsquash = {
  id: "5",
  status: "inactive",
  title: "Stellar Squash",
  link: "https://stellarsquash.com",
  soon: "Full portfolio page is coming soon. See the finished site here:",
  cover: "stellar-cover.jpg",
  description:
    "Stellar Squash - Customer-facing and wholesale site for USA expansion.",
  summary:
    "Stellar Squash - Customer-facing and wholesale site for USA expansion.",
  tools: [
    "Shopify",
    "Ruby On Rails",
    "Shopify Custom Theme",
    "HTML",
    "CSS",
    "React.JS",
    "Visual Design",
    "UX Design",
    "Ecommerce",
  ],
  content: (
    <>
      <p>
        <a
          href="https://stellarsquash.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Stellar Squash
        </a>{" "}
        - Customer-facing and wholesale site for USA expansion.
      </p>
      <ul>
        <li>
          Built on top of Shopify with a heavily customised theme including
          custom javascript for the product carousel.
        </li>
        <li>
          Initial designs were completed in Figma and then coded by myself to
          the website.
        </li>
        <li>Edited existing photography provided by client.</li>
        <li>Banner design and all design work is by me.</li>
      </ul>
    </>
  ),
};

export default stellarsquash;
